import { STATIC_URL, URL_PROTOCOL } from '@/config'
import { ALGOLIAFACETS } from '@/config/algolia/config'
import { INTER_DOMAIN } from '@/config/international/config'

export function isStaticPage (route) {
  const arrayUrl = STATIC_URL
  let staticPage = {
    static: false,
    type: null
  }
  if (route.path === '/') {
    staticPage = {
      static: true,
      type: 'home'
    }
  } else {
    arrayUrl.forEach((item) => {
      if (route.path.startsWith(item)) {
        staticPage = {
          static: true,
          type: 'other'
        }
      }
    })
  }
  return staticPage
}

export function sanitizeUrlParams (fullUrl, paramsToIgnore = ['soldes=1']) {
  const paramsInFullUrl = fullUrl.split('?').length > 1 ? fullUrl.split('?')[1].split(/\?|&(?=[^=&]+=[^&])/) : fullUrl.split('?')[1]
  let filteredParams = []

  if (paramsInFullUrl) {
    filteredParams = paramsInFullUrl.filter(param => paramsToIgnore.some((v) => {
      if (v.length === 0 || param.length === 0) {
        return false
      }
      return param.startsWith(v)
    }))
  }

  const sanitizedQuery = filteredParams.reduce((prev, curr, index) => {
    return prev.concat(`${index > 0 ? '&' : ''}${curr}`)
  }, '')

  const sanitizedFullUrl = sanitizedQuery.length ? fullUrl.split('?')[0].concat(`?${sanitizedQuery}`) : fullUrl.split('?')[0]

  return sanitizedFullUrl
}

export function isForbiddenQuery (query) {
  const whitelistParamNames = ['page', ...Object.values(ALGOLIAFACETS.names)]
  const paramValueConstraint = /^[a-z0-9~'#.\-/\s\u00C0-\u024F]*$/i

  return Object.entries(query).some(([key, value]) => {
    if (!whitelistParamNames.includes(key)) {
      return false
    }

    if (key === 'page' && !Number.isInteger(+value)) {
      return true
    }

    return !paramValueConstraint.test(value)
  })
}

export function breadcrumbSchema (breadcrumb, pageUrl) {
  const itemListElement = breadcrumb.map((item, index) => {
    const listItem = {}

    if (item.url) {
      Object.assign(listItem, {
        '@type': 'ListItem',
        position: index + 1,
        name: item.title,
        item: item.url.fullUrl
      })
    } else if (!item.url && index === (breadcrumb.length - 1)) {
      Object.assign(listItem, {
        '@type': 'ListItem',
        position: index + 1,
        name: item.title,
        item: pageUrl
      })
    }

    return listItem
  })
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement
  }
}

export function generateHrefLink (route, globalPageInfo) {
  const staticPage = isStaticPage(route)
  if (staticPage.static || !globalPageInfo?.seo) {
    return []
  }
  const hrefLinkArray = globalPageInfo.seo.alternateUrls.map((item) => {
    const sanitizeUrl = sanitizeUrlParams(item.url.fullUrl)

    return { hid: `i18n-alt-${item.lang}`, rel: 'alternate', href: sanitizeUrl, hreflang: item.lang }
  })

  const sanitizeRoute = sanitizeUrlParams(route.fullPath)

  return [
    ...hrefLinkArray,
    { hid: 'i18n-xd', rel: 'alternate', href: route.domain + sanitizeRoute, hreflang: 'x-default' }
  ]
}

export function generateStaticHrefLink (currentInterStoreId, availableInterStore, path = '') {
  return availableInterStore.map((item) => {
    if (item.id !== currentInterStoreId) {
      return { hid: `i18n-alt-${item.langCode}`, rel: 'alternate', href: `${URL_PROTOCOL}://${INTER_DOMAIN[item.id]}${path}`, hreflang: item.langCode }
    } else {
      return { hid: 'i18n-xd', rel: 'alternate', href: `${URL_PROTOCOL}://${INTER_DOMAIN[currentInterStoreId]}${path}`, hreflang: 'x-default' }
    }
  })
}

/**
 * 
 * @param {{ params: { ranges: string } }} filters 
 * @returns boolean
 */
export function isPageIndexed (filters) {
  const PARAM_TO_EXCLUDE = ['ranges', 'page']
  // recup tous les filters qui ont un item dans la raie (l'array)
  // si ces filtres recup ne correspondent pas au parametre a exclure alors on fait un no index
  const filtersParamsKey = Object.keys(filters.params) || []
  const filtersParamsRangeMaxLength = !!(filters?.params?.ranges && filters.params.ranges.split('~').length > 1)
  const filtersParamToExclude = filtersParamsKey.length === 0 ? false : filtersParamsKey.some(item => !PARAM_TO_EXCLUDE.includes(item))
  if (filtersParamToExclude || filters.sexes.length > 1 || filtersParamsRangeMaxLength) {
    return false
  }
  return true
}

export function generateSeo (context) {
  const { globalPageInfo, store, app, route } = context
  /*
  * On test en premier si on est sur une page static ou non
  * Grace au helper isStaticPage
  * Renvoie un objet de ce type :
  * { static:Boolean, type:String }
  * Page static: homePage, Toutes les pages account, Toutes les pages panier
  * Page dynamic: Toutes les autres pages (listing, detail produit, cms)
  *
  * Si on est sur une page static alors on utilise pas le parseUrl
  * On appel pas le getSeo pour recupérer les infos seo.
  *
  * Si on est sur une page dynamique :
  * On appel en premier le parseUrl pour recup le pageInfo de la page
  * On appel getSeo (function generateSeo) puis on stock le retour dans le store
  *
  * Les data concernant le seo vont être utilisés dans la mixins page
  */
  const staticPage = isStaticPage(route)
  if (staticPage.static) {
    store.dispatch('setSeo', {
      title: app.i18n.t(`seo.${staticPage.type}.title`),
      description: app.i18n.t(`seo.${staticPage.type}.description`),
      h1: null,
      header: null,
      footer: null
    })
  } else if (!staticPage.static && globalPageInfo) {
    store.dispatch('setSeo', globalPageInfo.seo)
  }
}
