import { getProduct, getProductInformations, getProductAssociated, createProductAlert } from '../services/product'
import { getMenu } from '../services/website'
import { getCarrierFreeShipping } from '../services/checkout'
import { getCmsPage } from '../services/cms'
import { subscribeNewsletter, unsubscribeNewsletter } from '../services/newsletter'
import { isOutletAccessAllowed } from '../services/outlet'
import { getAccountCustomerAddresses } from '../services/secure/account'
import { getOrder } from '../services/secure/order'
import { getReturnOrder } from '../services/secure/returnOrder'

export default function ({ $axios, $config, store, route, app }, inject) {
  $axios.setBaseURL($config.apiUrl)

  $axios.onRequest((config) => {
    const removeCache = route.query?.no_cache === '1' || false
    if (removeCache) {
      config.headers = {
        ...config.headers,
        'Cache-Control': 'no-store'
      }
    }
    return config
  })

  const deviceType = store.state.ui.deviceType

  const FRONT_HEADERS = {
    'Content-Type': 'application/json',
    'Lbo-Store': $config.store,
    'Lbo-Device': deviceType === 'pc' ? 0 : 1,
    'Accept-Language': $config.locale
  }

  const axiosFrontInstance = $axios.create({
    headers: {
      ...FRONT_HEADERS
    }
  })

  // Ensure there's an error object matching "error.response" object pattern
  // see https://axios-http.com/docs/handling_errors for more
  axiosFrontInstance.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    const errorObject = {
      response: {
        data: {
          code: 500,
          message: 'Internal Server Error'
        },
        status: 500,
        statusText: 'Internal Server Error',
        headers: null,
        config: null,
        extra: {}
      }
    }

    if (error.response) {
      return Promise.reject(error)
    } else if (error.request) {
      errorObject.response.data = {
        code: error.request.status ?? 500,
        message: error.request.statusText ?? 'Internal Server Error',
        state: error.request.readyState ?? null,
        headers: error.request.headers ?? null,
        config: error.request.config ?? null
      }

      return Promise.reject(errorObject)
    } else {
      if (error.code) {
        errorObject.response.data.code = error.code
      }

      if (error.message) {
        errorObject.response.data.message = error.message
      }

      if (error.config) {
        errorObject.response.config = error.config
      }

      errorObject.extra = error

      return Promise.reject(errorObject)
    }
  })

  axiosFrontInstance.onRequest((_config) => {
    if (!app.$auth) {
      return
    }

    const token = app.$auth.syncToken(app.$auth.$state.strategy)

    if (app.$auth.loggedIn) {
      axiosFrontInstance.setToken(token, 'Bearer')
    } else {
      delete _config.headers.Authorization
      delete _config.headers.common.Authorization
      /**
       * For unknown reasons (as it should be synchronous code) this function seems 
       * to run for next requests, not the current one. Thus, we're forced to
       * manually remove the Authorization header since API front has 
       * hybrid calls (change response accordingly to the presence or not of the Authorization field
       * in headers (eg. /basket/detail))
       **/
      axiosFrontInstance.setToken(false)
    }
  })

  axiosFrontInstance.setBaseURL(process.env.BACK_API_URL)

  inject('api', axiosFrontInstance)
  inject('locale', $config.locale)

  inject('getProduct', ({ productId }) => getProduct({
    productId,
    headers: { ...FRONT_HEADERS, 'Lbo-UrlVersion': 'v3' },
    axios: axiosFrontInstance
  }))

  inject('createProductAlert', productAlert => createProductAlert({
    productAlert,
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))

  inject('getProductInformations', ({ productId }) => getProductInformations({
    productId,
    headers: FRONT_HEADERS,
    axios: axiosFrontInstance
  }))

  inject('getProductAssociated', ({ productId }) => getProductAssociated({
    productId,
    headers: FRONT_HEADERS,
    axios: axiosFrontInstance
  }))

  inject('getMenu', () => getMenu({
    headers: FRONT_HEADERS,
    axios: axiosFrontInstance
  }))

  inject('getCarrierFreeShipping', () => getCarrierFreeShipping({
    headers: FRONT_HEADERS,
    axios: axiosFrontInstance
  }))

  inject('getAccountCustomerAddresses', () => getAccountCustomerAddresses({
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))

  inject('getCmsPage', pageKey => getCmsPage({
    pageKey,
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))

  inject('subscribeNewsletter', email => subscribeNewsletter({
    email,
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))

  inject('unsubscribeNewsletter', email => unsubscribeNewsletter({
    email,
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))

  inject('isOutletAccessAllowed', email => isOutletAccessAllowed({
    email,
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))

  inject('getOrder', orderId => getOrder({
    orderId,
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))

  inject('getReturnOrder', returnOrderId => getReturnOrder({
    returnOrderId,
    headers: FRONT_HEADERS,
    instance: axiosFrontInstance
  }))
}
